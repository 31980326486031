import { createWebHistory, createRouter } from "vue-router";
import LandingPage from "@/pages/LandingPage.vue";
import MainPage from "@/pages/MainPage.vue";
import Credit from "@/pages/Credit.vue";

const routes = [
  {
    path: "/",
    redirect: { path: '/de'} 
  },
  {
    path: "/:lang",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/:lang/content",
    name: "MainPageStart",
    component: MainPage,
  },
  {
    path: "/:lang/content/:id/:stagename?/:title?",
    name: "MainPage",
    component: MainPage,
  },
  {
    path: "/:lang/credits",
    name: "Credits",
    component: Credit,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;