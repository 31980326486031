import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-60b20e16")
const _hoisted_1 = {
  key: 0,
  class: "navigation-container"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavStageButton = _resolveComponent("NavStageButton")!

  return (this.$store.state.stages != null)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStages(), (stage, index) => {
          return (_openBlock(), _createBlock(_component_NavStageButton, {
            key: index,
            stage: stage
          }, null, 8, ["stage"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}