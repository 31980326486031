
import Navigation from "../partials/Navigation.vue";
import ContentFlow from "../partials/ContentFlow.vue";
import LoadingOverlay from "../partials/LoadingOverlay.vue"
import IO from "../common/io";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Footer from "../partials/Footer.vue";
import { onMounted, ref, watch } from '@vue/runtime-core';

export default {
  components: { Navigation, ContentFlow, Footer, LoadingOverlay },
  
  setup() {
    const store = useStore();
    const route = useRoute();

    const animationClass = ref(false);

    const getLang = () => {
      return route.params.lang;
    };

    window.addEventListener("resize", ()=>{
      store.commit("ResetBlockPositions", {});
    })
   
    const LoadData = () => {
      IO.Get("/stages?_locale=" + route.params.lang).then((response) => {

        store.commit("ResetBlockPositions", {});

        var stages = response.data;
        stages.sort((a: { Index: Number }, b: { Index: Number }) =>
          a.Index < b.Index ? -1 : 1
        );

        //console.log("SET STAGES ",stages)

        store.commit("SetStages", stages);
      });
    };

    LoadData();

    watch(()=>route.params.lang, ()=>{
      LoadData();
    })

    onMounted(()=>{
      animationClass.value = true;


      store.commit("SetGlobalBackground", true);
      store.state.world.sceneController.AnimateBackground("MainPage",2);
      store.state.world.postProcessingController.AnimatePostProcessSettings("MainPage",1);

      setTimeout(()=>{
        if(store.state.world.lineController != null){
          store.state.world.lineController.Remove();
        }
      },1000);

    });
    return { LoadData, getLang, animationClass, store };
  },
  
};
