
//import { ref } from "vue";
export default {
  props: {
    videoUrl: String,
    videoUrlHEVC: String,
  },
  setup() {
    /*const videoLoop = ref<HTMLVideoElement>();
    if (videoLoop.value != undefined) {
      //videoLoop.value.load();
      //videoLoop.value.play();
    }
    return { videoLoop };*/
  }
};
