import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b4005352")
const _hoisted_1 = { class: "video-inline" }
const _hoisted_2 = { class: "video-inline-inner" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoElement = _resolveComponent("VideoElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VideoElement, {
        videoUrl: $setup.getUrl(),
        videoUrlHEVC: $setup.getUrlHevc(),
        key: $setup.key
      }, null, 8, ["videoUrl", "videoUrlHEVC"])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}