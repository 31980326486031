import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-page page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_ContentFlow = _resolveComponent("ContentFlow")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-page-wrapper", {animationClass: $setup.animationClass}])
  }, [
    (!_ctx.$store.state.sceneReady)
      ? (_openBlock(), _createBlock(_component_LoadingOverlay, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        (this.$store.state.stages != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Navigation),
              _createVNode(_component_ContentFlow)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Footer, { class: "footer" })
  ], 2))
}