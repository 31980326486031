import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2a7e2e77")
const _hoisted_1 = { class: "overlay" }
const _hoisted_2 = {
  key: 1,
  class: "loading-ready"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$store.state.sceneReady)
      ? (_openBlock(), _createBlock(_component_Loading, {
          key: 0,
          class: "loading"
        }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.sceneReady)
      ? (_openBlock(), _createElementBlock("button", _hoisted_2, "Starten"))
      : _createCommentVNode("", true)
  ]))
}