<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { Pane } from "tweakpane";
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
import modes from 'glsl-blend/modes';

import IO from "../common/io";
import { watch } from "@vue/runtime-core";
import { Vector3 } from "three";

import SaveLocalJSON from '../common/SaveConsole';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    watch(
      () => store.state.world,
      (world) => {
        world.animationLoop.AddAnimationLoop(() => {

          fpsGraph.begin();
          fpsGraph.end();
        });
      }
    );

    let PARAMS = {
      value: 0,
      disableUI: false,


      //Post 
      updatePost : true,
      //Distortion
      distortion: 75,

      //Dof
      focus: 1.0,
      aperture: 0.025,
      maxblur: 0,

      //bloom
      bloom_threshold: 0.95,
      bloom_strength:2.9,
      bloom_radius: .2,


      smear: 0.92,

      far: 10000,
      near: 0.01,

      brightness: 1,
      contrast : 1,
      grayscale : 1,
      grayscaleLevel: {r: 0.21 * 255, g: 0.71 * 255, b: 0.07 * 255 },

      blendOpacity: 0.8,
      gradientOpacity: 1,
      gradientColor_1: {r: 255, g: 255, b: 255},
      gradientColor_2: {r: 255, g: 255, b: 255},
    };

    const pane = new Pane();
          pane.registerPlugin(EssentialsPlugin);

    

    const fpsMonitorFolder = pane.addFolder({
      title: "FPS Monitor",
      expanded: false,
    });
    const fpsGraph = fpsMonitorFolder.addBlade({
      view: 'fpsgraph',

      label: 'fpsgraph',
      lineCount: 2,
    });

    // Camera Settings
    const navigationFolder = pane.addFolder({
      title: "Navigation",
      expanded: false,
    });

    navigationFolder.addButton({ title: "home" }).on("click", () => {
      router.push({ path: "/" });
    });

    // Camera Settings
    const transformFolder = pane.addFolder({
      title: "Camera Settings",
      expanded: false,
    });

    transformFolder.addInput(PARAMS, "disableUI").on("change", (ev) => {
      if (ev.value) {
        document.body.classList.add("disable-ui");

        store.state.world.controls.instance.enabled = true;
      } else {
        document.body.classList.remove("disable-ui");
        store.state.world.controls.instance.enabled = false;
      }

      store.commit("ChangeDevSettings", {
        devChangesPerspective: ev.value,
      });
    });

    const farOptions = { min: 0.00001, max: 1000, step: 0.0001 };
    transformFolder
      .addInput(PARAMS, "near", { min: 0.00001, max: 100, step: 0.00001 })
      .on("change", (ev) => {
        store.state.world.camera.instance.near = ev.value;
        store.state.world.camera.instance.updateProjectionMatrix();
      });
    transformFolder
      .addInput(PARAMS, "far", farOptions)
      .on("change", (ev) => {
        store.state.world.camera.instance.far = ev.value;
        store.state.world.camera.instance.updateProjectionMatrix();
      });
    transformFolder
      .addButton({
        title: "Macro",
      })
      .on("click", () => {
        store.state.world.controls.instance.zoomSpeed = 1;
        store.state.world.controls.instance.rotateSpeed = 1;
        store.state.world.controls.instance.panSpeed = 1;

        farOptions.max = 1;

      });

    transformFolder
      .addButton({
        title: "Micro",
      })
      .on("click", () => {
        store.state.world.controls.instance.zoomSpeed = 0.1;
        store.state.world.controls.instance.rotateSpeed = 0.1;
        store.state.world.controls.instance.panSpeed = 0.1;
      });

    transformFolder
      .addButton({
        title: "Nano",
      })
      .on("click", () => {
        store.state.world.controls.instance.zoomSpeed = 0.01;
        store.state.world.controls.instance.rotateSpeed = 0.01;
        store.state.world.controls.instance.panSpeed = 0.01;
      });

    transformFolder
      .addButton({
        title: "Piko",
      })
      .on("click", () => {
        store.state.world.controls.instance.zoomSpeed = 0.0001;
        store.state.world.controls.instance.rotateSpeed = 0.0001;
        store.state.world.controls.instance.panSpeed = 0.0001;
      });

    transformFolder
      .addButton({
        title: "Reset Target",
      })
      .on("click", () => {
        store.state.world.controls.SetTarget(new Vector3(0, 0, 0));
      });

    transformFolder
      .addButton({
        title: "Get Transforms",
      })
      .on("click", () => {
        // console.log({
        //   Position: store.state.world.controls.GetPosition(),
        //   Target: store.state.world.controls.GetTarget(),
        // });
      });
      
    transformFolder
      .addButton({
        title: "Save Transforms",
      })
      .on("click", () => {
        IO.Put("/content-blocks/" + route.params.id, {
          Position: store.state.world.controls.GetPosition(),
          Target: store.state.world.controls.GetTarget(),
        }).then(()=>{
          alert("Saved Camera Transforms");
        });
      });

    const postFolder = pane.addFolder({
      title: "Postprocessing Settings",
      expanded: false,
    });

    postFolder.addInput(PARAMS, "updatePost").on("change", (ev) => {
      store.state.world.cameraTransition.dev.updatePost = ev.value;
    });

    // =>
    const distortionFolder = postFolder.addFolder({
      title: "Distortion Settings",
      expanded: false,
    });

  

    distortionFolder
      .addInput(PARAMS, "distortion", {
        min: 0,
        max: 180,
        step: 1,
      })
      .on("change", (ev) => {
        //console.log(ev.value);
        store.state.world.distortionEffect.SetDistortion(ev.value);
      });

    // => Bokeh Effect
    /*const bokehFolder = postFolder.addFolder({
      title: "Depth of Field Settings",
      expanded: false,
    });
    bokehFolder.hidden = false;

    bokehFolder
      .addInput(PARAMS, "focus", { min: 0, max: 100000, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.bokehEffect.pass.uniforms.focus.value = ev.value;
      });
    bokehFolder
      .addInput(PARAMS, "aperture", { min: 0, max: 0.1, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.bokehEffect.pass.uniforms.aperture.value = ev.value;
      });
    bokehFolder
      .addInput(PARAMS, "maxblur", { min: 0, max: 0.01, step: 0.001 })
      .on("change", (ev) => {
        console.log("SET MAXBLUR ")
        store.state.world.bokehEffect.pass.uniforms.maxblur.value = ev.value;
      });*/


    // => BLOOM
    const bloomFolder = postFolder.addFolder({
      title: "Bloom",
      expanded: true,
    });
    bloomFolder
      .addInput(PARAMS, "bloom_threshold", { min: 0, max: 1, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.bloomEffect.pass.threshold = ev.value;
      });
    bloomFolder
      .addInput(PARAMS, "bloom_strength", { min: 0, max: 3, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.bloomEffect.pass.strength = ev.value;
      });
    bloomFolder
      .addInput(PARAMS, "bloom_radius", { min: 0, max: 2, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.bloomEffect.pass.radius = ev.value;
      });


    // => SMEAR
    const smearFolder = postFolder.addFolder({
      title: "Smear",
      expanded: false,
    });
    smearFolder.hidden = false;
    smearFolder
      .addInput(PARAMS, "smear", { min: 0, max: 1, step: 0.001 })
      .on("change", (ev) => {
        store.state.world.blendPass.uniforms.mixRatio.value = ev.value;
      });

   

       const colorFolder = postFolder.addFolder({
          title: "Color Grading",
          expanded: false,
        });

        colorFolder.addInput(PARAMS, 'brightness',{
          min : 0,
          max : 2,

        }).on("change" , (ev)=>{
          //console.log(ev.value,store.state.world.colorGradingEffect);  

          store.state.world.colorGradingEffect.pass.uniforms.brightness.value = ev.value * 1.0;


        });
        
        colorFolder.addInput(PARAMS, 'contrast',{
          min : 0,
          max : 1,          
        }).on("change" , (ev)=>{

          store.state.world.colorGradingEffect.pass.uniforms.contrast.value = ev.value * 1.0;
          //console.log(ev.value);
        });
        
        colorFolder.addInput(PARAMS, 'grayscale',{
          min: -1,
          max: 1
        }).on("change" , (ev)=>{
          store.state.world.colorGradingEffect.pass.uniforms.grayscale.value = ev.value;
        });
        
        colorFolder.addInput(PARAMS, 'grayscaleLevel').on("change" , (ev)=>{
          store.state.world.colorGradingEffect.pass.uniforms.grayscaleLevel.value.setRGB(ev.value.r / 255,ev.value.g / 255,ev.value.b / 255);
        });

    const gradientColorFolder = postFolder.addFolder({
          title: "Gradient Overlay",
          expanded: false,
        });

        
        gradientColorFolder.addInput(PARAMS, 'blendOpacity', {min:0,max:1}).on("change" , (ev)=>{
          store.state.world.gradientEffect.pass.uniforms.blendOpacity.value = ev.value;
        });

        const blendOptions = Object.keys(modes).map(blendName => {return { text: blendName, value: modes[blendName]}});
        gradientColorFolder.addBlade({
          view: 'list',
          label: 'scene',
          options: blendOptions,
          value: 17,
        }).on("change", (ev)=>{
          //console.log(ev.value, modes)
          store.state.world.gradientEffect.pass.uniforms.blendModeIndex.value = ev.value;

        });

        gradientColorFolder.addInput(PARAMS, 'gradientOpacity', {min:0,max:1}).on("change" , (ev)=>{
          store.state.world.gradientEffect.pass.uniforms.opacity.value = ev.value;
        });
        
        gradientColorFolder.addInput(PARAMS, 'gradientColor_1').on("change" , (ev)=>{
          store.state.world.gradientEffect.pass.uniforms.gradientColor_1.value.setRGB(ev.value.r / 255,ev.value.g / 255,ev.value.b / 255);
        });
        
        gradientColorFolder.addInput(PARAMS, 'gradientColor_2').on("change" , (ev)=>{
          store.state.world.gradientEffect.pass.uniforms.gradientColor_2.value.setRGB(ev.value.r / 255,ev.value.g / 255,ev.value.b / 255);
        });


  const GetPostProcessingSettings = ()=>{
    return {
          PostProcessingSettings: {
            DepthOfField: {
              Focus: store.state.world.bokehEffect.pass.uniforms.focus.value,
              Aperture:
                store.state.world.bokehEffect.pass.uniforms.aperture.value,
              Maxblur:
                store.state.world.bokehEffect.pass.uniforms.maxblur.value,
            },
            Smear: store.state.world.blendPass.uniforms.mixRatio.value,
            bloom: {
              radius:     store.state.world.bloomEffect.pass.radius,
              strength:   store.state.world.bloomEffect.pass.strength,
              threshold:  store.state.world.bloomEffect.pass.threshold
            },
            ColorGrading:{
              brightness : store.state.world.colorGradingEffect.pass.uniforms.brightness.value,
              contrast : store.state.world.colorGradingEffect.pass.uniforms.contrast.value,
              grayscale : store.state.world.colorGradingEffect.pass.uniforms.grayscale.value,
              grayscaleLevel : store.state.world.colorGradingEffect.pass.uniforms.grayscaleLevel.value
            },
            GradientBlending:{
              gradientColor_1 : store.state.world.gradientEffect.pass.uniforms.gradientColor_1.value,
              gradientColor_2 : store.state.world.gradientEffect.pass.uniforms.gradientColor_2.value,
              gradientOpacity : store.state.world.gradientEffect.pass.uniforms.opacity.value,
              blendOpacity : store.state.world.gradientEffect.pass.uniforms.blendOpacity.value,
              blendModeIndex : store.state.world.gradientEffect.pass.uniforms.blendModeIndex.value
            }
          }
        }
  }

    //Export Button
      postFolder
      .addButton({
        title: "Save PostSettings as JSON",
      })
      .on("click", () => {
        const postSettings = GetPostProcessingSettings();
        SaveLocalJSON(postSettings);     
      });
      
      
      postFolder
      .addButton({
        title: "Export PostSettings",
      })
      .on("click", () => {
        //console.log(pane.exportPreset());
        //pane.exportPreset();
        

        const postSettings = {
          PostProcessingSettings: {
            DepthOfField: {
              Focus: store.state.world.bokehEffect.pass.uniforms.focus.value,
              Aperture:
                store.state.world.bokehEffect.pass.uniforms.aperture.value,
              Maxblur:
                store.state.world.bokehEffect.pass.uniforms.maxblur.value,
            },
            Smear: store.state.world.blendPass.uniforms.mixRatio.value,
            bloom: {
              radius:     store.state.world.bloomEffect.pass.radius,
              strength:   store.state.world.bloomEffect.pass.strength,
              threshold:  store.state.world.bloomEffect.pass.threshold
            }
          },
        }

        //console.log(postSettings, "/content-blocks/" + route.params.id);
        IO.Put("/content-blocks/" + route.params.id, postSettings);
      });

  },

  render() {
    return null;
  },
};
</script>

<style lang="scss">

.tp-dfwv {
  position: fixed !important;
  right: 40px !important;
  z-index: 99999;
}

.disable-ui {
  .wrapper {
    pointer-events: none;
  }
}
</style>