
import { nextTick, onMounted, onUpdated, ref } from "vue";
import { useStore } from "vuex";
// import Quote from "./Quote.vue";
// import Image from "./Image.vue";
// import InlineVideo from "./InlineVideo.vue";
// import YouTube from "./YouTube.vue"

import marked from 'marked';
import GraphicAddon from './GraphicAddon.vue';

export default {
  components: {  GraphicAddon }, //Quote, Image, InlineVideo,YouTube,
  props: {
    block: Object,
    itemNumber:Number
  },
  setup(props: any) {
    const store = useStore();
    const animationClass = ref(false);
    //const scrollContainer = ref(null)

    window.addEventListener("resize" , ()=>{

      SetOffsetTop();
    })

    const root = ref(null);

    function CanRenderText(text:string | null){
      return text != null && typeof(text) != "undefined";
    }

    function SetOffsetTop(){
      if (props.block != null) {

        nextTick(()=>{
          var id = props.block.id;
          // @ts-ignore: Object is possibly 'null'
          var offset = root.value.offsetTop;
          store.commit("SetInitialPositions", {
            id: id,
            yPos: offset,
          });
        });
      }
    }
    onMounted(() => {
      SetOffsetTop();

      animationClass.value = true;
    });

    onUpdated(()=>{
      nextTick(() => {
        SetOffsetTop();
      })
    })

    return { root , marked, CanRenderText, animationClass};
  },
};
