<template>
  <div class="graphics-container">
    <Image 
      v-if="GetComponentType('layout-addon.image')" 
      :image="GetComponentByType('layout-addon.image')">
        <Quote
          v-if="GetComponentType('layout-addon.quote', false)"
          :quote="GetComponentByType('layout-addon.quote', false)"
        />
      </Image>
    <InlineVideo 
      v-if="GetComponentType('layout-addon.video',false)" 
      :video="GetComponentByType('layout-addon.video',false)"
      :videoHEVC="GetComponentByType('layout-addon.video',false)">
        <Quote
          v-if="GetComponentType('layout-addon.quote', false)"
          :quote="GetComponentByType('layout-addon.quote', false)"
        />
    </InlineVideo>
    
    <YouTube 
      v-if="GetComponentType('layout-addon.you-tube',false)" 
      :video="GetComponentByType('layout-addon.you-tube', false)">
        <Quote
          v-if="GetComponentType('layout-addon.quote', false)"
          :quote="GetComponentByType('layout-addon.quote', false)"
        />
        
      </YouTube>
  </div>
</template>

<script>
import Image from './Image.vue'
import InlineVideo from './InlineVideo.vue';
import Quote from './Quote.vue';
import YouTube from './YouTube.vue';
export default {
  components: { Image, InlineVideo,  Quote , YouTube},
  props: {
    graphicsAddon: Object,
  },
  setup(props) {

    function GetComponentType(type, returnData=true){
      const addon = props.graphicsAddon.find(component => component.__component === type);
      if(typeof(addon) === "undefined" || addon == null){return false}      
      if(addon.Data == null && returnData) {return false}

      return true;
    }

    function GetComponentByType(type, returnData=true){
      
      if(!GetComponentType(type, returnData)){return null;}
      const addon = props.graphicsAddon.find(component => component.__component === type);
      return returnData ? addon.Data : addon;
    }

    return {GetComponentType, GetComponentByType};    
  },
}
</script>