import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d7280c86")
const _hoisted_1 = { class: "zitat" }
const _hoisted_2 = { class: "outer-bubble" }
const _hoisted_3 = { class: "content-inner" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "inner-bubble",
        style: _normalizeStyle({
          backgroundImage: 'url(' + $setup.getUrl() + ')',
        })
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("q", null, _toDisplayString($props.quote.Text), 1)
        ])
      ], 4)
    ])
  ]))
}