
import { useStore } from "vuex";

import NavStageButton from "../components/NavStageButton.vue";

export default {
  components: {
    NavStageButton,
  },
  setup() {
    const store = useStore();
    const getStages = () => {
      //console.log("STAGES ",Object.values(store.state.stages))
      return Object.values(store.state.stages);
    };
    return { getStages };
  },
}; //
