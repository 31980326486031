import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "credits-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "close-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.Close && $setup.Close(...args)))
    }, [
      _createVNode(_component_CloseIcon)
    ]),
    ($setup.cmsData != null)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($setup.cmsData.Title), 1))
      : _createCommentVNode("", true),
    ($setup.cmsData != null && $setup.CanRenderText($setup.cmsData.Text))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "credits-container-content",
          innerHTML: $setup.GetMarkDown($setup.cmsData.Text)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer)
  ]))
}