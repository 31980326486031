import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-41ec46a6")
const _hoisted_1 = {
  ref: "videoLoop",
  muted: "",
  autoplay: "",
  loop: "",
  playsinline: ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", _hoisted_1, [
    ($props.videoUrlHEVC != null)
      ? (_openBlock(), _createElementBlock("source", {
          key: 0,
          src: $props.videoUrlHEVC,
          type: "video/mp4; codecs=\"hvc1\""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("source", {
      src: $props.videoUrl,
      type: "video/webm"
    }, null, 8, _hoisted_3)
  ], 512))
}