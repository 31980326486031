import { MultiplyBlending } from 'three';
import { createStore } from 'vuex'
import NeurocureWorld from './Scene/NeurocureWorld';
import IWorld from './Scene/Setup/World/IWorld';

// Create a new store instance.
export default createStore({
  state() {
    return {
      stages: {},
      currentStage: null,
      lastStage: null,
      world : null,
      mute : false,
      library : null,
      loadingProgress : 0,
      sceneReady : false,
      blockPositions: null,
      isFullScreen: null,
      InStartTransition : false,
      startAnimationLerp:0,
      showClouds: false,
      transitionObject: {
        currentBlock: null,
        nextBlock: null,
        alpha: null
      },
      devOptions : {
        devChangesPerspective : false
      },
      cookiesAccepted:false
    }
  },
  mutations: {
    SetWorld(state:any, world:IWorld){
      state.world = world;
    },
    SetClouds(state:any, data){
      state.showClouds = data;
    },
    SetGlobalBackground(state:any, data){
      state.backgroundChange = data;
    },
    SetStages(state: any, data) {
      state.stages = data;
    },
    SetCurrentStage(state: any, data) {
      state.lastStage = state.currentStage;
      state.currentStage = data;
    },
    SetLoadingProgress(state:any,data){
      state.loadingProgress=data;
    },
    ResetBlockPositions(state:any, data){
      state.blockPositions = data;
    },
    SetInitialPositions(state: any, data) {
      if(state.blockPositions == null){state.blockPositions = {} }

      const blockPositionCopy = Object.assign({}, state.blockPositions);
      blockPositionCopy[data.id] = data.yPos;

      state.blockPositions = blockPositionCopy;
    },
    SetTransitionObject(state: any, data) {
      state.transitionObject = data;
    },
    
    ChangeDevSettings(state: any, data) {
      state.devOptions = Object.assign(state.devOptions, data);
    },
    SetLibrary(state:any, data){
      state.library = data;
    },
    SetSceneReady(state:any, data){
      state.sceneReady = data;
    },
    SetFullScreen(state:any, data){
      state.isFullScreen = data;

      if(data){
        document.body.classList.add("fullscreen-video");
      }else{
        document.body.classList.remove("fullscreen-video");
      }
    },
    SetStartTransition(state:any, data){
      state.InStartTransition = data;
    },
    SetStartAnimationLerp(state:any, data){
      state.startAnimationLerp = data;
    },AcceptCookies(state:any, data){
state.cookiesAccepted= data;
    }
  }
});

