
import { useRouter, useRoute } from "vue-router";
import IO from "../common/io";
import { ref, watch } from "vue";

//import { ref } from "vue";

export default {
  components: {},
  props: {
    showLang: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cmsData = ref();

    const SetLanguage = (langParam: string) => {
      router.replace({ params: { lang: langParam } });
    };

    const getLang = () => {
      return route.params.lang;
    };

    const LoadData = () => {
      IO.Get("/imprint-pages?_locale=" + route.params.lang).then((response) => {
        cmsData.value = response.data[0];
      });
    };

    LoadData();

    watch(
      () => route.params.lang,
      async () => {
        LoadData();
      }
    );

    return { SetLanguage, getLang, route, cmsData };
  },
}; //
