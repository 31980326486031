import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_TopButton = _resolveComponent("TopButton")!

  return (_openBlock(), _createElementBlock("main", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStages(), (stage, i) => {
      return (_openBlock(), _createElementBlock("section", { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getSortedBlock(stage), (block, index) => {
          return (_openBlock(), _createBlock(_component_ContentBlock, {
            key: index,
            block: block,
            itemNumber: index
          }, null, 8, ["block", "itemNumber"]))
        }), 128))
      ]))
    }), 128)),
    ($setup.store.state.currentStage != null)
      ? (_openBlock(), _createBlock(_component_TopButton, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}