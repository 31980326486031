<template>
  <div :class="'camera-overlay ' + animationClass" :style="{backgroundImage:'url('+bgImage+')'}" ></div>
</template>

<script>
import { useStore } from "vuex";
import { watch, ref } from "vue";

import bgImage from '@/assets/clouds.png';
export default {
  setup() {
    const store = useStore();
    var animationClass = ref("");
    watch(
      () => store.state.showClouds,
      async () => {
        //animationClass.value = "fadeInAnimation";

        //console.log("showClouds");
      }
    );
    return { store, animationClass,bgImage };
  },
};
</script>

<style lang="scss" scoped>
$opacity: .94;
@keyframes fadeIn {
  0% {
    opacity: 0;
    background-size: 100% auto;
  }
  50% {
    opacity: 1;
    background-size: 100% auto;
  }
  
  100% {
    opacity: 0;
    background-size: 1000% auto
  }
}


.camera-overlay {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  z-index: 999;
  background-size: cover;
  background-position: center;
  pointer-events: none;
  opacity: 0;
}
.fadeInAnimation {
  animation-duration: .6s;
  animation-name: fadeIn;
}
</style>