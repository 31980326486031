
import CloseIcon from "../icons/CloseIcon.vue";
import { useStore } from "vuex";

export default {
  components: {
    CloseIcon,
  },
  props: {},
  setup() {
    const store = useStore();
    const StopFullScreen = () => {
      store.commit("SetFullScreen", null);
    };
    return { StopFullScreen };
  },
};
