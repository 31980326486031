
import mainConfig from "../../main.config";
export default {
  props: {
    image: Object,
  },
  setup(props: any) {
    const config = mainConfig;
    const getUrl = () => {
      
      if(typeof(props.image.formats.medium) != "undefined"){
        return config.API_URL + props.image.formats.medium.url;
      }else{
        return config.API_URL + props.image.formats.thumbnail.url;
      }

    };
    
    const getAlt = () => {
      return props.image.alternativeText;
    };
    return { config, getUrl, getAlt };
  },
};
