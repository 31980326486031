import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f2c72af2")
const _hoisted_1 = {
  key: 0,
  class: "video outer-bubble"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayIcon = _resolveComponent("PlayIcon")!

  return ($props.video.VideoID != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "inner-bubble video-bubble",
          style: _normalizeStyle({ backgroundImage: 'url(' + $setup.getUrl() + ')' }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.StartFullScreen($props.video.VideoID)))
        }, [
          _createVNode(_component_PlayIcon, { class: "play-icon" })
        ], 4),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
    : _createCommentVNode("", true)
}