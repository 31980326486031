
import mainConfig from "../../main.config";
export default {
  props: {
    quote: Object,
  },
  setup(props: any) {
    const config = mainConfig;
    const getUrl = () => {
      if(props.quote?.Image?.formats.small == undefined){
        return
      }
      if(props.quote?.Image?.formats.small.url == null){
        return;
      }
      return config.API_URL + props.quote?.Image?.formats.small.url;
    };
    return { config, getUrl };
  },
};
