
import ContentBlock from "./ContentBlock.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import TopButton from "../components/TopButton.vue"
//import { watch} from "vue";
export default {
  components: {
    ContentBlock,
    TopButton
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let count = 0;

    const getStages = () => {
      return store.state.stages;
    };

    var isFirst = true;
    const getSortedBlock = (stage: { content_blocks: any }) => {
      if (isFirst && stage.content_blocks.length > 0) {
        isFirst = false;
        
        // @ts-ignore: Unreachable code error
        let urlhandle = stage.urlhandle;
        let title = stage.content_blocks[0].urlhandle == null ? "" : stage.content_blocks[0].urlhandle;
       
        router.push('/'+route.params.lang+'/content/' + stage.content_blocks[0].id + "/" +  urlhandle  + "/" +  title);
      }

      stage.content_blocks.sort((a:any,b:any) => {
        let orderA = parseFloat(a.order.replace("order_", "")); 
        let orderB = parseFloat(b.order.replace("order_", "")); 
        if(orderA < orderB){
          return -1;
        }else if(orderA > orderB){
          return 1;
        }//console.log(block)

        return 0;
      });

      return stage.content_blocks;
    }

    const AdditiveContentBlock = (add:number) => {
      count += add;
      return count;
    }
    
    return { getStages, getSortedBlock, AdditiveContentBlock, store};
  },
};
