
import mainConfig from "../../main.config";
import PlayIcon from "../icons/PlayIcon.vue";

import { ref } from "vue";
import {useStore} from "vuex"
export default {
  components: {
    PlayIcon

  },
  props: {
    video: Object,
  },
  setup(props: any) {
    const config = mainConfig;
    const store = useStore()
    let localIsFullScreen = ref(false);

    const getUrl = () => {
      if (props.video?.Poster == null) {
        return;
      }

      if (props.video?.Poster.formats?.small == undefined) {
        return;
      }
      return config.API_URL + props.video?.Poster.formats?.small.url;
    };

    const StartFullScreen = (id:string) => {
      store.commit("SetFullScreen",id);
    };

    return { getUrl, StartFullScreen, localIsFullScreen };
  },
};
