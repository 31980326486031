import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-79f39fec")
const _hoisted_1 = { class: "content-container-inner" }
const _hoisted_2 = { class: "text-container" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GraphicAddon = _resolveComponent("GraphicAddon")!

  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["content-container", {even: ($props.itemNumber % 2 == 0),odd: ($props.itemNumber % 2 != 0) , special: $props.block.Text == '' || $props.block.Text == null,animationClass:$setup.animationClass}]),
    ref: "root"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_GraphicAddon, {
        graphicsAddon: $props.block.GraphicAddOn
      }, null, 8, ["graphicsAddon"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", {
          id: $props.block.id
        }, [
          ($props.block.academicTitle != '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.block.academicTitle), 1))
            : _createCommentVNode("", true),
          _createTextVNode(_toDisplayString($props.block.Title), 1)
        ], 8, _hoisted_3),
        ($props.block.SubTitle != '' )
          ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString($props.block.SubTitle), 1))
          : _createCommentVNode("", true),
        ($setup.CanRenderText($props.block.Text))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "text",
              innerHTML: $setup.marked($props.block.Text)
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}