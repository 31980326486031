
import mainConfig from "../../main.config";
import VideoElement from "./VideoElement.vue"
import { watch, ref } from "vue";
//import { useRoute} from "vue-router";
export default {
  props: {
    video: Object,
  },
  components:{VideoElement},
  setup(props: any) {
    const config = mainConfig;
    
    var key = ref(0);
    watch(
      () => props.video.Data,
      () => {
          key.value += 1;
      }
    );

    const getUrl = () => {
      return config.API_URL + props.video.Data.url;
    };

    const getUrlHevc = () => {
      if (props.video.DataHEVC.url == null) {
        return "";
      }
      return config.API_URL + props.video.DataHEVC.url;
    };

    return { getUrl, getUrlHevc,  key };
  },
};
