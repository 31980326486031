
import IO from "../common/io";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import CloseIcon from "../icons/CloseIcon.vue";
import Footer from "../partials/Footer.vue";
import mainConfig from "../../main.config";

import marked from "marked";
import { useStore } from 'vuex';

export default {
  components: {
    CloseIcon,
    Footer,
  },
  props: {
    block: Object,
  },
  mounted() {
    const store = useStore();
    store.state.world.scrollController.enabled = false;
    //console.log("credit mounted");


  },
  setup() {
    const cmsData = ref();
    const route = useRoute();
    const router = useRouter();

    const Close = () => {
      router.back();
    };

    function CanRenderText(text: string | null) {
      return text != null && typeof text != "undefined";
    }

    const LoadData = () => {
      IO.Get("/imprint-pages?_locale=" + route.params.lang).then((response) => {
        cmsData.value = response.data[0];
      });
    };

    LoadData();

    watch(
      () => route.params.lang,
      async () => {
        LoadData();
      }
    );

    function GetMarkDown(text: string) {
      text = text.replace(
        /\(\/uploads/g,
        "(" + mainConfig.API_URL + "/uploads/"
      );

      return marked(text);
    }

    return { cmsData, LoadData, Close, GetMarkDown, CanRenderText };
  },
};
