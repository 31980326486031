
import IO from "../common/io";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

//import NeurocureIcon from "../icons/neurocure_icon.vue";
import Loading from "../components/Loading.vue";
import Footer from '../partials/Footer.vue';

export default {
  props: {
    block: Object,
  },
  components: { Loading, Footer }, //NeurocureIcon
  setup() {
    const cmsData = ref();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const setLang = (lang: string) => {
      router.replace({ params: { lang: lang } });
    };
    const getLang = () => {
      return route.params.lang;
    };

    const LoadData = () => {
      //console.log("CHANGE  LANGUAGE ", route.params.lang);
      IO.Get("/landing-pages?_locale=" + route.params.lang).then((response) => {
        cmsData.value = response.data[0];
      });

    };

    function AddBlending(){

      document.body.classList.add("start-blending");
      
    }

    LoadData();

    watch(
      () => route.params.lang,
      async () => {
        LoadData();
      }
    );

    return { cmsData, store, LoadData, setLang, getLang, AddBlending };
  },
};
