import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2411702c")
const _hoisted_1 = { class: "outer-bubble no-bg" }
const _hoisted_2 = { class: "inner-bubble" }
const _hoisted_3 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.getUrl(),
        alt: $setup.getAlt()
      }, null, 8, _hoisted_3)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}